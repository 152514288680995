import React from 'react';

import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { GET_BOX_SCORE, GET_ITA_PERSON_BY_ID } from 'src/queries/ITA';

import { Scorecard } from '@clubspark-react/ui';

import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import { PageMaxWidth } from '../util-components/util-components';
import { dualMatchType } from './box-score-types';
import { getSections, getSectionSubtitle, reformatMatches } from './box-score.utils';

interface Props {
  playerId?: string;
  id?: string;
}

const BoxScore: React.FC<Props> = ({ playerId, id }) => {
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(GET_BOX_SCORE, {
    client: meshGatewayClient,
    variables: {
      dualMatchId: id,
    },
  });

  const {
    data: personData,
    error: personError,
    loading: personLoading,
  } = useQuery(GET_ITA_PERSON_BY_ID, {
    client: meshGatewayClient,
    variables: {
      uniqueId: playerId,
      uniqueIdType: 'personId',
    },
  });

  const fullName = `${personData?.personById?.standardGivenName} ${personData?.personById?.standardFamilyName}`;

  const dualMatch = data?.dualMatch;

  const homeTeamId = dualMatch?.homeTeam?.id;

  const singlesMatches = dualMatch?.tieMatchUps.filter((matchUp) => matchUp.type === 'SINGLES');
  const doublesMatches = dualMatch?.tieMatchUps.filter((matchUp) => matchUp.type === 'DOUBLES');

  const reformattedSinglesMatches = reformatMatches(singlesMatches);
  const reformattedDoublesMatches = reformatMatches(doublesMatches);

  const homeTeam = dualMatch?.teams?.find((team) => team.id === homeTeamId);
  const awayTeam = homeTeam ? dualMatch?.teams?.find((team) => team.id !== homeTeamId) : undefined;

  const scorecardData: dualMatchType = {
    heading: dualMatch?.startDateTime?.dateTimeString
      ? t('dateFormat.MMM DD, YYYY (ddd) hh mm A', {
          date: moment(dualMatch?.startDateTime?.dateTimeString).local(),
        })
      : '-',
    subheading: '',
    sections: getSections(
      t,
      dualMatch,
      doublesMatches,
      singlesMatches,
      reformattedDoublesMatches,
      reformattedSinglesMatches,
    ),
    sides: [
      {
        headerText: t('home'),
        name: homeTeam?.name,
        subtitleText: getSectionSubtitle(homeTeam),
        rank: homeTeam?.sideNumber,
        score: homeTeam?.score,
        avatar: <img src={homeTeam?.logo?.url} width={'59 * @px'}></img>,
      },
      {
        headerText: t('away'),
        name: awayTeam?.name,
        subtitleText: getSectionSubtitle(awayTeam),
        rank: awayTeam?.sideNumber,
        score: awayTeam?.score,
        avatar: <img src={awayTeam?.logo?.url} width={'59 * @px'}></img>,
      },
    ],
  };

  const teamMatchesClasses = {
    section: 'section',
    sectionHeading: 'heading',
    versusBanner: 'banner',
    sectionSubtitle: 'sectionSubtitle',
  };

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          { name: t('players'), to: '/players' },
          { name: fullName, to: `/players/${playerId}` },
          { name: t('box score'), active: true },
        ]}
      />

      <Scorecard
        adornment={'none'}
        classNames={{
          section: teamMatchesClasses.section,
          sectionHeading: teamMatchesClasses.sectionHeading,
          versusBanner: teamMatchesClasses.versusBanner,
          sectionSubtitle: teamMatchesClasses.sectionSubtitle,
        }}
        enableMenu={false}
        hideHeaders={true}
        heading={scorecardData?.heading}
        subheading={scorecardData?.subheading}
        nonInteractiveMatches={true}
        sections={scorecardData?.sections}
        showAdditionalInfo={false}
        showPlayerMenuIndicator={false}
        showTitleHeader
        sides={scorecardData?.sides}
      />
    </PageMaxWidth>
  );
};

export default BoxScore;
