import React from 'react';

import { Breadcrumbs as MUIBreadcrumbs } from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'gatsby';

import Icon from 'src/components/icon/icon';
import * as styles from './breadcrumbs.module.less';

export interface IPath {
  name: string;
  to?: string;
  active?: boolean;
  highlight?: boolean;
}
interface Props {
  paths: IPath[];
}

const Breadcrumbs: React.FC<Props> = ({ paths }: Props) => {
  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      classes={{ separator: styles.breadcrumbsSeparator }}
      separator={<Icon name="sm-right" />}
    >
      {paths.map((path, name) => {
        if (path.active) {
          return (
            <span key={path.name} className={styles.active}>
              {path.name}
            </span>
          );
        }

        return (
          <Link
            key={path.to || path.name}
            to={path.to ? path.to : '/'}
            className={classNames({
              [styles.highlight]: path.highlight,
              [styles.breadcrumbs]: !path.highlight,
            })}
          >
            {path.name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
