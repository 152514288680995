import { IMatch, IMatchSection, IPlayer, IScore, ISideScore } from './box-score-types';

export function getName(firstName: string | undefined, lastName: string | undefined): string | undefined {
  if (!firstName || !lastName) return undefined;
  return `${firstName} ${lastName}`;
}

function getWinningSide(side1set, side2set) {
  if (side1set?.didWin) {
    return 1;
  } else if (side2set?.didWin) {
    return 2;
  } else {
    return undefined;
  }
}

export function getScore(match): IScore[] | undefined {
  const side1Sets = match?.side1?.score?.sets;
  const side2Sets = match?.side2?.score?.sets;

  const setsLength = side1Sets?.length <= side2Sets?.length ? side1Sets?.length : side2Sets?.length;

  if (setsLength === 0) return undefined;

  const score: { winningSide: number | undefined; sideScores: ISideScore[] }[] = [];

  for (let i = 0; i < setsLength; i++) {
    const side1set = side1Sets[i];
    const side2set = side2Sets[i];

    const setScores = {
      winningSide: getWinningSide(side1set, side2set),
      sideScores: [
        {
          score: side1set?.setScore ? parseInt(side1set?.setScore) : undefined,
          tiebreak: side1set?.tiebreakScore ? parseInt(side1set?.tiebreakScore) : undefined,
        },
        {
          score: side2set?.setScore ? parseInt(side2set?.setScore) : undefined,
          tiebreak: side2set?.tiebreakScore ? parseInt(side2set?.tiebreakScore) : undefined,
        },
      ],
    };

    score.push(setScores);
  }

  return score;
}

function getSide(side): IPlayer[] {
  return side?.participants?.map((p) => ({
    id: p.personId,
    name: getName(p.firstName, p.lastName),
  }));
}

export function reformatMatches(matchesArray): IMatch[] {
  const reformattedMatches = matchesArray?.map(
    (match): IMatch => ({
      id: match.id,
      completed: match?.status === 'COMPLETED',
      index: match.collectionPosition,
      showStatus: false,
      score: getScore(match),
      sides: [
        {
          status: match?.side1?.didWin ? 'WINNER' : undefined,
          players: getSide(match?.side1),
        },
        {
          status: match?.side2?.didWin ? 'WINNER' : undefined,
          players: getSide(match?.side2),
        },
      ],
    }),
  );

  return reformattedMatches;
}

export function getSections(
  translation,
  dualMatch,
  doublesMatches,
  singlesMatches,
  reformattedDoublesMatches,
  reformattedSinglesMatches,
): IMatchSection[] {
  const sections: { name: string; matches: IMatch[]; sectionSubtitle: any }[] = [];

  if (doublesMatches?.length > 0) {
    sections.push({
      name: translation('doubles matches'),
      matches: reformattedDoublesMatches,
      sectionSubtitle: dualMatch?.doublesOrderFinish?.length
        ? `${translation('order of finish')}: ${dualMatch?.doublesOrderFinish.join(', ')}`
        : undefined,
    });
  }
  if (singlesMatches?.length > 0) {
    sections.push({
      name: translation('singles matches'),
      matches: reformattedSinglesMatches,
      sectionSubtitle: dualMatch?.singlesOrderFinish?.length
        ? `${translation('order of finish')}: ${dualMatch?.singlesOrderFinish.join(', ')}`
        : undefined,
    });
  }

  return sections;
}

export function getSectionSubtitle(team) {
  const division = team?.division || '';
  const conference = team?.conference || '';
  const spacing = team?.division && team?.conference ? ' - ' : '';

  return `${division}${spacing}${conference}`;
}
